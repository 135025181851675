body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body
  .select-dropdown-orders
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f0f5f5;
}
body
  .select-dropdown-orders
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item {
  padding: 0;
}
body
  .select-dropdown-orders
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-state {
  display: none !important;
}
body
  .select-dropdown-orders
  .ant-select-item-option-active
  .select-item-option-quantity-active {
  background-color: #f0f5f5;
}
body
  .select-dropdown-orders
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-selected {
  background-color: #f0fcfc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
